import { Button, Card, Col, Divider, Input, Modal, Row, Space } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { TopBar } from "../../components/header";
import { api } from "../../service";

interface FormFieldProps {
  fieldName: string;
  fieldValue: string;
}
interface FieldProps {
  _id: string;
  title: string;
  data: any;
  createdAt: string;
  updatedAt: string;
}

export const Snnipet = () => {
  const [title, setTitle] = useState<string>("Titulo");
  const [formInstances, setFormInstances] = useState<FormFieldProps[][]>([
    [{ fieldName: "Campo_1", fieldValue: "" }],
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [urlSnnipet, setUrlSnnipet] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const transformObjectToFields = (obj: FieldProps) => {
    return obj.data.map((dataObj: any) =>
      Object.entries(dataObj).map(([fieldName, fieldValue]) => ({
        fieldName,
        fieldValue,
      }))
    );
  };

  const cloneForm = () => {
    let clonedForm = formInstances[0].map((field) => ({ ...field }));
    clonedForm = clonedForm.map((item) => ({
      fieldName: item.fieldName,
      fieldValue: "",
    }));
    setFormInstances([...formInstances, clonedForm]);
  };

  const handleChange = (
    formIndex: number,
    fieldIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    let instances = formInstances as any;
    instances[formIndex][fieldIndex][name] = value;
    setFormInstances([...instances]);
  };

  const handleChangeName = (
    fieldIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    let instances = formInstances.map((form) => [...form]) as any;
    instances.forEach(
      (_: any, index: number) => (instances[index][fieldIndex][name] = value)
    );
    setFormInstances(instances);
  };

  const handleAddField = () => {
    const instances = formInstances.map((form) => [...form]);
    instances.forEach((form) => {
      form.push({
        fieldName: `Campo_${formInstances[0].length + 1}`,
        fieldValue: "",
      });
    });
    setFormInstances(instances);
  };

  const removeObjectByIndex = (index: number) => {
    setFormInstances((prevData) => {
      if (index < 0 || index >= prevData.length) {
        return prevData;
      }
      return [...prevData.slice(0, index), ...prevData.slice(index + 1)];
    });
  };

  const copyText = (): void => {
    const elementoTemporario = document.createElement("textarea");
    elementoTemporario.value = urlSnnipet;
    document.body.appendChild(elementoTemporario);
    elementoTemporario.select();
    document.execCommand("copy");
    document.body.removeChild(elementoTemporario);
  };

  const postData = async () => {
    const data = {
      title: title,
      data: formInstances.map((form) =>
        form.reduce((obj: any, field) => {
          obj[field.fieldName] = field.fieldValue;
          return obj;
        }, {})
      ),
    };
    try {
      const response = await api.post("/snippets/", data);
      toast.success("Sucesso ao realizar a crição do snnipet");
      setUrlSnnipet(
        `https://ig-snippets-backoffice.pages.dev/${response.data._id}`
      );
      showModal();
    } catch (error) {
      toast.error("Erro ao realizar a criação");
    }
  };

  const patchData = async () => {
    const data = {
      title: title,
      data: formInstances.map((form) =>
        form.reduce((obj: any, field) => {
          obj[field.fieldName] = field.fieldValue;
          return obj;
        }, {})
      ),
    };
    try {
      await api.patch(`/snippets/${id}`, data);
      toast.success("Sucesso ao realizar a edição");
    } catch (error) {
      toast.error("Erro ao realizar a edição");
    }
  };

  const deleteData = async () => {
    try {
      await api.delete(`/snippets/${id}`);
      toast.success("Sucesso ao realizar a deleção");
      navigate("/list");
      setFormInstances([[{ fieldName: "Campo_1", fieldValue: "" }]]);
    } catch (error) {
      toast.error("Erro ao realizar a deleção");
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModalDelete = () => {
    setOpen(true);
  };

  const handleOkDelete = () => {
    deleteData();
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 1000);
  };

  const handleCancelDelete = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(
          `/snippets/${id}?${new Date().getTime()}`
        );
        setFormInstances(transformObjectToFields(response.data));
        setTitle(response.data.title);
      } catch (error) {
        navigate("/");
        toast.error("Erro ao realizar a busca do snnipet");
      }
    };
    if (id) {
      getData();
    }
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopBar />
      <div className="container">
        {loading && (
          <div className="space">
            <Card className="space">
              Titulo
              <Input
                type="text"
                name="Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                placeholder="Field Name"
              />
            </Card>
            {formInstances?.map((form, formIndex) => (
              <Card key={formIndex} className="space">
                {form.map((field, fieldIndex) => (
                  <Row wrap key={fieldIndex}>
                    <Col flex="auto" className="space">
                      Campo
                      <Input
                        type="text"
                        name="fieldName"
                        value={field.fieldName}
                        onChange={(event) =>
                          handleChangeName(fieldIndex, event)
                        }
                        placeholder="Field Name"
                      />
                    </Col>
                    <Col flex="auto" className="space">
                      Valor
                      <Input
                        type="text"
                        name="fieldValue"
                        value={field.fieldValue}
                        onChange={(event) =>
                          handleChange(formIndex, fieldIndex, event)
                        }
                        placeholder="Field Value"
                      />
                    </Col>
                  </Row>
                ))}
                {formIndex > 0 && (
                  <Button
                    onClick={() => removeObjectByIndex(formIndex)}
                    className="space"
                  >
                    Excluir
                  </Button>
                )}
              </Card>
            ))}
            <Divider />
            <Space wrap className="space">
              <Button onClick={handleAddField}>Adicionar Campo</Button>
              <Button onClick={cloneForm}>Clonar campos</Button>
              <Button onClick={id ? patchData : postData} type="primary">
                {id ? "Editar" : "Enviar"}
              </Button>
              {id && (
                <Button onClick={showModalDelete} danger>
                  Excluir
                </Button>
              )}
            </Space>
          </div>
        )}
      </div>
      <Modal
        title="Url do snippet"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row wrap>
          <Col span={20}>
            <a href={urlSnnipet}>{urlSnnipet}</a>
          </Col>
          <Col span={4}>
            <Button onClick={copyText}>Copiar</Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Deletar snippet"
        open={open}
        onOk={handleOkDelete}
        confirmLoading={confirmLoading}
        onCancel={handleCancelDelete}
      >
        <p>Tem certeza que deseja deletar esse snippet?</p>
      </Modal>
    </>
  );
};
