import axios from "axios";

export const BASE_API_URL = "https://service.ig.com.br/snippets";

const authenticated = localStorage.getItem("access_token");

export const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Authorization: `Bearer ${authenticated}`,
  },
});
