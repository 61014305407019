import { Form, Input, Button, Card, Space } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { api } from "../../service";
import { toast } from "react-hot-toast";

export const Login = () => {
  const onFinish = async (values: { email: string; password: string }) => {
    try {
      const response = await api.post("/auth/login", values);
      localStorage.setItem("access_token", response.data.access_token);
      window.location.href = "/";
    } catch (error) {
      toast.error("Erro ao realizar o login");
    }
  };

  return (
    <div className="login-container">
      <Space>
        <Card>
          <h1 className="login-title">Login</h1>
          <Form
            name="loginForm"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Por favor, insira seu usuário!" },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Usuário" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Por favor, insira sua senha!" },
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Senha" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-button">
                Entrar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Space>
    </div>
  );
};
