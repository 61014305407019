import { FileTextOutlined } from "@ant-design/icons";
import { Divider, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { TopBar } from "../../components/header";
import { api } from "../../service";

interface DataType {
  _id: string;
}

interface FieldProps {
  _id: string;
  title: string;
  data: any;
  createdAt: string;
  updatedAt: string;
}

export const Snnipets = () => {
  const [data, setData] = useState<FieldProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: "Titulo",
      dataIndex: "title",
      render: (title, record) => (
        <span
          onClick={(e) => {
            navigate(`/${record?._id as string}`);
          }}
        >
          {title}
        </span>
      ),
    },
    {
      title: "JSON",
      dataIndex: "",
      render: (_, record) => (
        <span
          onClick={(e) => {
            window.location.href = `https://service.ig.com.br/snippets/${record?._id}`;
          }}
        >
          <FileTextOutlined />
        </span>
      ),
      width: 50,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/snippets/?${new Date().getTime()}`);
        setData(response.data);
      } catch (error) {
        toast.error("Erro ao realizar a busca do snnipet");
      }
      setLoading(true);
    };
    fetchData();
  }, []);

  return (
    <>
      <TopBar />
      {loading && (
        <div className="container">
          <Divider />
          <Table columns={columns} dataSource={data} />
        </div>
      )}
    </>
  );
};
